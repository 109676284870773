// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$JSON from "@kaiko.io/rescript-deser/lib/es6/src/JSON.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Mithril from "mithril";
import * as Settings from "../Settings.js";
import * as AuthToken from "../global/models/AuthToken.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as React from "@sentry/react";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as MithrilRequest from "./MithrilRequest.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";

function withAuth(request, token) {
  var tk = Curry._2(Prelude.OptionExported.$$Option.map, token, AuthToken.Access.toString);
  if (tk !== undefined && tk !== "") {
    return MithrilRequest.addHeaders(request, [[
                  "Authorization",
                  "Bearer " + tk
                ]]);
  } else {
    return request;
  }
}

var BearerAuthenticator = {
  withAuth: withAuth
};

function MakeReadonlyAPIEndpoint(D) {
  var Deserializer = D.Deserializer;
  var UnexpectedAPIPayload = /* @__PURE__ */Caml_exceptions.create("APIMaker.MakeReadonlyAPIEndpoint(D).UnexpectedAPIPayload");
  var _getManyPage = function (ids, params, token, body) {
    var request = Curry._1(Prelude.OptionExported.$$Option.isSome, body) ? MithrilRequest.post(D.url) : MithrilRequest.get(D.url);
    var request$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, body, (function (body) {
                return MithrilRequest.body(request, body);
              })), request);
    var request$2 = withAuth(request$1, token);
    var payload = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ids, (function (ids) {
                return Caml_option.some(Object.assign(Prelude.default(params, {}), {
                                id__in: Belt_SetString.toArray(Belt_SetString.fromArray(ids)).map(function (prim) {
                                        return encodeURIComponent(prim);
                                      }).join(",")
                              }));
              })), params);
    var request$3 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, payload, (function (payload) {
                return MithrilRequest.params(request$2, payload);
              })), request$2);
    return Prelude.catchResolve(Prelude.PromisedResult.bind(MithrilRequest.$$do(request$3), (function (response) {
                      var items = Js_json.classify(response);
                      if (typeof items !== "object") {
                        if (items === "JSONNull") {
                          return Promise.resolve({
                                      TAG: "Ok",
                                      _0: []
                                    });
                        }
                        throw {
                              RE_EXN_ID: UnexpectedAPIPayload,
                              _1: "Expected a list of items.",
                              Error: new Error()
                            };
                      } else {
                        switch (items.TAG) {
                          case "JSONObject" :
                              var parsed = Deserializer.fromJSON(response);
                              if (parsed.TAG === "Ok") {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: [parsed._0]
                                          });
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse the result from the API endpoint: " + parsed._0,
                                    Error: new Error()
                                  };
                          case "JSONArray" :
                              var items$1 = items._0;
                              if (items$1.length === 0) {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: []
                                          });
                              }
                              var parsed$1 = Prelude.ManyResults.bail(items$1.map(Deserializer.fromJSON));
                              if (parsed$1.TAG === "Ok") {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: parsed$1._0
                                          });
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse some item in the list of the API endpoint: " + parsed$1._0,
                                    Error: new Error()
                                  };
                          default:
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Expected a list of items.",
                                  Error: new Error()
                                };
                        }
                      }
                    })), (function (e) {
                  React.captureException(e);
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var _getMany = function (params, token, body, ids) {
    if (ids === undefined) {
      return _getManyPage(undefined, params, token, body);
    }
    var chunks = Prelude.$$Array.chunks(ids, 45);
    return Prelude.PromisedResult.map(Prelude.PromisedResult.bail(chunks.map(function (ids) {
                        return function () {
                          return _getManyPage(ids, params, token, undefined);
                        };
                      })), (function (results) {
                  return Curry._1(Prelude.$$Array.concatMany, results);
                }));
  };
  var getMany = function (params, token, ids) {
    return _getMany(params, token, undefined, ids);
  };
  var getAll = function (params, token) {
    return _getMany(params, token, undefined, undefined);
  };
  var getAllWithPost = function (params, body, token) {
    return _getMany(params, token, body, undefined);
  };
  var get = function (params, token, id) {
    var promise;
    if (D.url.endsWith("/:id")) {
      var params$1 = Object.assign(Prelude.default(params, {}), {
            id: id
          });
      promise = _getManyPage(undefined, Caml_option.some(params$1), token, undefined);
    } else {
      promise = _getManyPage([id], params, token, undefined);
    }
    return Prelude.catchResolve(Prelude.PromisedResult.map(promise, (function (result) {
                      var len = result.length;
                      if (len === 1) {
                        return Caml_option.some(result[0]);
                      }
                      if (len === 0) {
                        return ;
                      }
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: "Got more than one item, expecting 0 or 1",
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var options = function (params, token) {
    var request = MithrilRequest.payload(MithrilRequest.options(D.url), params);
    var request$1 = withAuth(request, token);
    return MithrilRequest.$$do(request$1);
  };
  return {
          Deserializer: Deserializer,
          UnexpectedAPIPayload: UnexpectedAPIPayload,
          getMany: getMany,
          getAll: getAll,
          getAllWithPost: getAllWithPost,
          get: get,
          options: options
        };
}

function MakeBackendReadonlyAPIEndpoint(D) {
  var url = D.url;
  var Deserializer = D.Deserializer;
  var base = Settings.BackendAPI.apiBaseUrl;
  var base$1 = base.endsWith("/") ? base : base + "/";
  var path = url.startsWith("/") ? url.slice(1) : url;
  var url$1 = base$1 + path;
  var UnexpectedAPIPayload = /* @__PURE__ */Caml_exceptions.create("APIMaker.MakeReadonlyAPIEndpoint(D).UnexpectedAPIPayload");
  var _getManyPage = function (ids, params, token, body) {
    var request = Curry._1(Prelude.OptionExported.$$Option.isSome, body) ? MithrilRequest.post(url$1) : MithrilRequest.get(url$1);
    var request$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, body, (function (body) {
                return MithrilRequest.body(request, body);
              })), request);
    var request$2 = withAuth(request$1, token);
    var payload = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ids, (function (ids) {
                return Caml_option.some(Object.assign(Prelude.default(params, {}), {
                                id__in: Belt_SetString.toArray(Belt_SetString.fromArray(ids)).map(function (prim) {
                                        return encodeURIComponent(prim);
                                      }).join(",")
                              }));
              })), params);
    var request$3 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, payload, (function (payload) {
                return MithrilRequest.params(request$2, payload);
              })), request$2);
    return Prelude.catchResolve(Prelude.PromisedResult.bind(MithrilRequest.$$do(request$3), (function (response) {
                      var items = Js_json.classify(response);
                      if (typeof items !== "object") {
                        if (items === "JSONNull") {
                          return Promise.resolve({
                                      TAG: "Ok",
                                      _0: []
                                    });
                        }
                        throw {
                              RE_EXN_ID: UnexpectedAPIPayload,
                              _1: "Expected a list of items.",
                              Error: new Error()
                            };
                      } else {
                        switch (items.TAG) {
                          case "JSONObject" :
                              var parsed = Deserializer.fromJSON(response);
                              if (parsed.TAG === "Ok") {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: [parsed._0]
                                          });
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse the result from the API endpoint: " + parsed._0,
                                    Error: new Error()
                                  };
                          case "JSONArray" :
                              var items$1 = items._0;
                              if (items$1.length === 0) {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: []
                                          });
                              }
                              var parsed$1 = Prelude.ManyResults.bail(items$1.map(Deserializer.fromJSON));
                              if (parsed$1.TAG === "Ok") {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: parsed$1._0
                                          });
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse some item in the list of the API endpoint: " + parsed$1._0,
                                    Error: new Error()
                                  };
                          default:
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Expected a list of items.",
                                  Error: new Error()
                                };
                        }
                      }
                    })), (function (e) {
                  React.captureException(e);
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var _getMany = function (params, token, body, ids) {
    if (ids === undefined) {
      return _getManyPage(undefined, params, token, body);
    }
    var chunks = Prelude.$$Array.chunks(ids, 45);
    return Prelude.PromisedResult.map(Prelude.PromisedResult.bail(chunks.map(function (ids) {
                        return function () {
                          return _getManyPage(ids, params, token, undefined);
                        };
                      })), (function (results) {
                  return Curry._1(Prelude.$$Array.concatMany, results);
                }));
  };
  var getMany = function (params, token, ids) {
    return _getMany(params, token, undefined, ids);
  };
  var getAll = function (params, token) {
    return _getMany(params, token, undefined, undefined);
  };
  var getAllWithPost = function (params, body, token) {
    return _getMany(params, token, body, undefined);
  };
  var get = function (params, token, id) {
    var promise;
    if (url$1.endsWith("/:id")) {
      var params$1 = Object.assign(Prelude.default(params, {}), {
            id: id
          });
      promise = _getManyPage(undefined, Caml_option.some(params$1), token, undefined);
    } else {
      promise = _getManyPage([id], params, token, undefined);
    }
    return Prelude.catchResolve(Prelude.PromisedResult.map(promise, (function (result) {
                      var len = result.length;
                      if (len === 1) {
                        return Caml_option.some(result[0]);
                      }
                      if (len === 0) {
                        return ;
                      }
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: "Got more than one item, expecting 0 or 1",
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var options = function (params, token) {
    var request = MithrilRequest.payload(MithrilRequest.options(url$1), params);
    var request$1 = withAuth(request, token);
    return MithrilRequest.$$do(request$1);
  };
  return {
          Deserializer: Deserializer,
          UnexpectedAPIPayload: UnexpectedAPIPayload,
          getMany: getMany,
          getAll: getAll,
          getAllWithPost: getAllWithPost,
          get: get,
          options: options
        };
}

function MakeAPIEndpoint(D) {
  var Deserializer = D.Deserializer;
  var UnexpectedAPIPayload = /* @__PURE__ */Caml_exceptions.create("APIMaker.MakeReadonlyAPIEndpoint(D).UnexpectedAPIPayload");
  var _getManyPage = function (ids, params, token, body) {
    var request = Curry._1(Prelude.OptionExported.$$Option.isSome, body) ? MithrilRequest.post(D.url) : MithrilRequest.get(D.url);
    var request$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, body, (function (body) {
                return MithrilRequest.body(request, body);
              })), request);
    var request$2 = withAuth(request$1, token);
    var payload = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ids, (function (ids) {
                return Caml_option.some(Object.assign(Prelude.default(params, {}), {
                                id__in: Belt_SetString.toArray(Belt_SetString.fromArray(ids)).map(function (prim) {
                                        return encodeURIComponent(prim);
                                      }).join(",")
                              }));
              })), params);
    var request$3 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, payload, (function (payload) {
                return MithrilRequest.params(request$2, payload);
              })), request$2);
    return Prelude.catchResolve(Prelude.PromisedResult.bind(MithrilRequest.$$do(request$3), (function (response) {
                      var items = Js_json.classify(response);
                      if (typeof items !== "object") {
                        if (items === "JSONNull") {
                          return Promise.resolve({
                                      TAG: "Ok",
                                      _0: []
                                    });
                        }
                        throw {
                              RE_EXN_ID: UnexpectedAPIPayload,
                              _1: "Expected a list of items.",
                              Error: new Error()
                            };
                      } else {
                        switch (items.TAG) {
                          case "JSONObject" :
                              var parsed = Deserializer.fromJSON(response);
                              if (parsed.TAG === "Ok") {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: [parsed._0]
                                          });
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse the result from the API endpoint: " + parsed._0,
                                    Error: new Error()
                                  };
                          case "JSONArray" :
                              var items$1 = items._0;
                              if (items$1.length === 0) {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: []
                                          });
                              }
                              var parsed$1 = Prelude.ManyResults.bail(items$1.map(Deserializer.fromJSON));
                              if (parsed$1.TAG === "Ok") {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: parsed$1._0
                                          });
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse some item in the list of the API endpoint: " + parsed$1._0,
                                    Error: new Error()
                                  };
                          default:
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Expected a list of items.",
                                  Error: new Error()
                                };
                        }
                      }
                    })), (function (e) {
                  React.captureException(e);
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var _getMany = function (params, token, body, ids) {
    if (ids === undefined) {
      return _getManyPage(undefined, params, token, body);
    }
    var chunks = Prelude.$$Array.chunks(ids, 45);
    return Prelude.PromisedResult.map(Prelude.PromisedResult.bail(chunks.map(function (ids) {
                        return function () {
                          return _getManyPage(ids, params, token, undefined);
                        };
                      })), (function (results) {
                  return Curry._1(Prelude.$$Array.concatMany, results);
                }));
  };
  var getMany = function (params, token, ids) {
    return _getMany(params, token, undefined, ids);
  };
  var getAll = function (params, token) {
    return _getMany(params, token, undefined, undefined);
  };
  var get = function (params, token, id) {
    var promise;
    if (D.url.endsWith("/:id")) {
      var params$1 = Object.assign(Prelude.default(params, {}), {
            id: id
          });
      promise = _getManyPage(undefined, Caml_option.some(params$1), token, undefined);
    } else {
      promise = _getManyPage([id], params, token, undefined);
    }
    return Prelude.catchResolve(Prelude.PromisedResult.map(promise, (function (result) {
                      var len = result.length;
                      if (len === 1) {
                        return Caml_option.some(result[0]);
                      }
                      if (len === 0) {
                        return ;
                      }
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: "Got more than one item, expecting 0 or 1",
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var options = function (params, token) {
    var request = MithrilRequest.payload(MithrilRequest.options(D.url), params);
    var request$1 = withAuth(request, token);
    return MithrilRequest.$$do(request$1);
  };
  var postMany = function (params, token, items) {
    var request = MithrilRequest.post(D.url);
    var request$1 = withAuth(request, token);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, params, (function (params) {
                return MithrilRequest.params(request$1, params);
              })), request$1);
    return Prelude.catchResolve(Prelude.PromisedResult.bind(MithrilRequest.$$do(MithrilRequest.payload(request$2, items)), (function (result) {
                      var items = Js_json.classify(result);
                      var res;
                      if (typeof items !== "object") {
                        if (items === "JSONNull") {
                          res = {
                            TAG: "Ok",
                            _0: []
                          };
                        } else {
                          throw {
                                RE_EXN_ID: UnexpectedAPIPayload,
                                _1: "Unexpected response",
                                Error: new Error()
                              };
                        }
                      } else {
                        switch (items.TAG) {
                          case "JSONObject" :
                              res = Curry._2(Prelude.Result.map, Deserializer.fromJSON(result), (function (x) {
                                      return [x];
                                    }));
                              break;
                          case "JSONArray" :
                              res = Prelude.ManyResults.bail(items._0.map(Deserializer.fromJSON));
                              break;
                          default:
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Unexpected response",
                                  Error: new Error()
                                };
                        }
                      }
                      if (res.TAG === "Ok") {
                        return Promise.resolve({
                                    TAG: "Ok",
                                    _0: res._0
                                  });
                      }
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: res._0,
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var postOne = function (params, token, item) {
    var request = MithrilRequest.post(D.url);
    var request$1 = withAuth(request, token);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, params, (function (params) {
                return MithrilRequest.params(request$1, params);
              })), request$1);
    return Prelude.catchResolve(Prelude.PromisedResult.bind(MithrilRequest.$$do(MithrilRequest.payload(request$2, item)), (function (result) {
                      var match = Js_json.classify(result);
                      var res;
                      if (typeof match !== "object") {
                        if (match === "JSONNull") {
                          res = {
                            TAG: "Ok",
                            _0: undefined
                          };
                        } else {
                          throw {
                                RE_EXN_ID: UnexpectedAPIPayload,
                                _1: "Unexpected response",
                                Error: new Error()
                              };
                        }
                      } else {
                        switch (match.TAG) {
                          case "JSONObject" :
                              res = Curry._2(Prelude.Result.map, Deserializer.fromJSON(result), (function (x) {
                                      return Caml_option.some(x);
                                    }));
                              break;
                          case "JSONArray" :
                              res = Curry._2(Prelude.Result.map, Deserializer.fromJSON(result), (function (x) {
                                      return Caml_option.some(x);
                                    }));
                              break;
                          default:
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Unexpected response",
                                  Error: new Error()
                                };
                        }
                      }
                      if (res.TAG === "Ok") {
                        return Promise.resolve({
                                    TAG: "Ok",
                                    _0: res._0
                                  });
                      }
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: res._0,
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var postForm = function (params, token, form) {
    var request = MithrilRequest.post(D.url);
    var request$1 = withAuth(request, token);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, params, (function (params) {
                return MithrilRequest.params(request$1, params);
              })), request$1);
    return Prelude.catchResolve(Prelude.PromisedResult.mapError(Prelude.thenResolve(Mithril.request(MithrilRequest.form(request$2, form)), Deserializer.fromJSON), (function (msg) {
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: msg,
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  return {
          Deserializer: Deserializer,
          UnexpectedAPIPayload: UnexpectedAPIPayload,
          getMany: getMany,
          get: get,
          getAll: getAll,
          options: options,
          postMany: postMany,
          postOne: postOne,
          postForm: postForm
        };
}

function MakeBackendAPIEndpoint(D) {
  var url = D.url;
  var Deserializer = D.Deserializer;
  var base = Settings.BackendAPI.apiBaseUrl;
  var base$1 = base.endsWith("/") ? base : base + "/";
  var path = url.startsWith("/") ? url.slice(1) : url;
  var url$1 = base$1 + path;
  var UnexpectedAPIPayload = /* @__PURE__ */Caml_exceptions.create("APIMaker.MakeReadonlyAPIEndpoint(D).UnexpectedAPIPayload");
  var _getManyPage = function (ids, params, token, body) {
    var request = Curry._1(Prelude.OptionExported.$$Option.isSome, body) ? MithrilRequest.post(url$1) : MithrilRequest.get(url$1);
    var request$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, body, (function (body) {
                return MithrilRequest.body(request, body);
              })), request);
    var request$2 = withAuth(request$1, token);
    var payload = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ids, (function (ids) {
                return Caml_option.some(Object.assign(Prelude.default(params, {}), {
                                id__in: Belt_SetString.toArray(Belt_SetString.fromArray(ids)).map(function (prim) {
                                        return encodeURIComponent(prim);
                                      }).join(",")
                              }));
              })), params);
    var request$3 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, payload, (function (payload) {
                return MithrilRequest.params(request$2, payload);
              })), request$2);
    return Prelude.catchResolve(Prelude.PromisedResult.bind(MithrilRequest.$$do(request$3), (function (response) {
                      var items = Js_json.classify(response);
                      if (typeof items !== "object") {
                        if (items === "JSONNull") {
                          return Promise.resolve({
                                      TAG: "Ok",
                                      _0: []
                                    });
                        }
                        throw {
                              RE_EXN_ID: UnexpectedAPIPayload,
                              _1: "Expected a list of items.",
                              Error: new Error()
                            };
                      } else {
                        switch (items.TAG) {
                          case "JSONObject" :
                              var parsed = Deserializer.fromJSON(response);
                              if (parsed.TAG === "Ok") {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: [parsed._0]
                                          });
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse the result from the API endpoint: " + parsed._0,
                                    Error: new Error()
                                  };
                          case "JSONArray" :
                              var items$1 = items._0;
                              if (items$1.length === 0) {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: []
                                          });
                              }
                              var parsed$1 = Prelude.ManyResults.bail(items$1.map(Deserializer.fromJSON));
                              if (parsed$1.TAG === "Ok") {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: parsed$1._0
                                          });
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse some item in the list of the API endpoint: " + parsed$1._0,
                                    Error: new Error()
                                  };
                          default:
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Expected a list of items.",
                                  Error: new Error()
                                };
                        }
                      }
                    })), (function (e) {
                  React.captureException(e);
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var _getMany = function (params, token, body, ids) {
    if (ids === undefined) {
      return _getManyPage(undefined, params, token, body);
    }
    var chunks = Prelude.$$Array.chunks(ids, 45);
    return Prelude.PromisedResult.map(Prelude.PromisedResult.bail(chunks.map(function (ids) {
                        return function () {
                          return _getManyPage(ids, params, token, undefined);
                        };
                      })), (function (results) {
                  return Curry._1(Prelude.$$Array.concatMany, results);
                }));
  };
  var getMany = function (params, token, ids) {
    return _getMany(params, token, undefined, ids);
  };
  var getAll = function (params, token) {
    return _getMany(params, token, undefined, undefined);
  };
  var get = function (params, token, id) {
    var promise;
    if (url$1.endsWith("/:id")) {
      var params$1 = Object.assign(Prelude.default(params, {}), {
            id: id
          });
      promise = _getManyPage(undefined, Caml_option.some(params$1), token, undefined);
    } else {
      promise = _getManyPage([id], params, token, undefined);
    }
    return Prelude.catchResolve(Prelude.PromisedResult.map(promise, (function (result) {
                      var len = result.length;
                      if (len === 1) {
                        return Caml_option.some(result[0]);
                      }
                      if (len === 0) {
                        return ;
                      }
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: "Got more than one item, expecting 0 or 1",
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var options = function (params, token) {
    var request = MithrilRequest.payload(MithrilRequest.options(url$1), params);
    var request$1 = withAuth(request, token);
    return MithrilRequest.$$do(request$1);
  };
  var postMany = function (params, token, items) {
    var request = MithrilRequest.post(url$1);
    var request$1 = withAuth(request, token);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, params, (function (params) {
                return MithrilRequest.params(request$1, params);
              })), request$1);
    return Prelude.catchResolve(Prelude.PromisedResult.bind(MithrilRequest.$$do(MithrilRequest.payload(request$2, items)), (function (result) {
                      var items = Js_json.classify(result);
                      var res;
                      if (typeof items !== "object") {
                        if (items === "JSONNull") {
                          res = {
                            TAG: "Ok",
                            _0: []
                          };
                        } else {
                          throw {
                                RE_EXN_ID: UnexpectedAPIPayload,
                                _1: "Unexpected response",
                                Error: new Error()
                              };
                        }
                      } else {
                        switch (items.TAG) {
                          case "JSONObject" :
                              res = Curry._2(Prelude.Result.map, Deserializer.fromJSON(result), (function (x) {
                                      return [x];
                                    }));
                              break;
                          case "JSONArray" :
                              res = Prelude.ManyResults.bail(items._0.map(Deserializer.fromJSON));
                              break;
                          default:
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Unexpected response",
                                  Error: new Error()
                                };
                        }
                      }
                      if (res.TAG === "Ok") {
                        return Promise.resolve({
                                    TAG: "Ok",
                                    _0: res._0
                                  });
                      }
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: res._0,
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var postOne = function (params, token, item) {
    var request = MithrilRequest.post(url$1);
    var request$1 = withAuth(request, token);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, params, (function (params) {
                return MithrilRequest.params(request$1, params);
              })), request$1);
    return Prelude.catchResolve(Prelude.PromisedResult.bind(MithrilRequest.$$do(MithrilRequest.payload(request$2, item)), (function (result) {
                      var match = Js_json.classify(result);
                      var res;
                      if (typeof match !== "object") {
                        if (match === "JSONNull") {
                          res = {
                            TAG: "Ok",
                            _0: undefined
                          };
                        } else {
                          throw {
                                RE_EXN_ID: UnexpectedAPIPayload,
                                _1: "Unexpected response",
                                Error: new Error()
                              };
                        }
                      } else {
                        switch (match.TAG) {
                          case "JSONObject" :
                              res = Curry._2(Prelude.Result.map, Deserializer.fromJSON(result), (function (x) {
                                      return Caml_option.some(x);
                                    }));
                              break;
                          case "JSONArray" :
                              res = Curry._2(Prelude.Result.map, Deserializer.fromJSON(result), (function (x) {
                                      return Caml_option.some(x);
                                    }));
                              break;
                          default:
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Unexpected response",
                                  Error: new Error()
                                };
                        }
                      }
                      if (res.TAG === "Ok") {
                        return Promise.resolve({
                                    TAG: "Ok",
                                    _0: res._0
                                  });
                      }
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: res._0,
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var postForm = function (params, token, form) {
    var request = MithrilRequest.post(url$1);
    var request$1 = withAuth(request, token);
    var request$2 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, params, (function (params) {
                return MithrilRequest.params(request$1, params);
              })), request$1);
    return Prelude.catchResolve(Prelude.PromisedResult.mapError(Prelude.thenResolve(Mithril.request(MithrilRequest.form(request$2, form)), Deserializer.fromJSON), (function (msg) {
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: msg,
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  return {
          Deserializer: Deserializer,
          UnexpectedAPIPayload: UnexpectedAPIPayload,
          getMany: getMany,
          get: get,
          getAll: getAll,
          options: options,
          postMany: postMany,
          postOne: postOne,
          postForm: postForm
        };
}

function MakePaginatedEndpoint(D) {
  var url = D.url;
  var default_results = [];
  var $$default = {
    count: 0,
    results: default_results
  };
  var fields = {
    TAG: "Object",
    _0: [
      [
        "count",
        "Int"
      ],
      [
        "results",
        {
          TAG: "Collection",
          _0: D.Deserializer
        }
      ]
    ]
  };
  var Deserializer = $$JSON.MakeDeserializer({
        fields: fields
      });
  var base = Settings.BackendAPI.apiBaseUrl;
  var base$1 = base.endsWith("/") ? base : base + "/";
  var path = url.startsWith("/") ? url.slice(1) : url;
  var url$1 = base$1 + path;
  var UnexpectedAPIPayload = /* @__PURE__ */Caml_exceptions.create("APIMaker.MakeReadonlyAPIEndpoint(D).UnexpectedAPIPayload");
  var _getManyPage = function (ids, params, token, body) {
    var request = Curry._1(Prelude.OptionExported.$$Option.isSome, body) ? MithrilRequest.post(url$1) : MithrilRequest.get(url$1);
    var request$1 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, body, (function (body) {
                return MithrilRequest.body(request, body);
              })), request);
    var request$2 = withAuth(request$1, token);
    var payload = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, ids, (function (ids) {
                return Caml_option.some(Object.assign(Prelude.default(params, {}), {
                                id__in: Belt_SetString.toArray(Belt_SetString.fromArray(ids)).map(function (prim) {
                                        return encodeURIComponent(prim);
                                      }).join(",")
                              }));
              })), params);
    var request$3 = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, payload, (function (payload) {
                return MithrilRequest.params(request$2, payload);
              })), request$2);
    return Prelude.catchResolve(Prelude.PromisedResult.bind(MithrilRequest.$$do(request$3), (function (response) {
                      var items = Js_json.classify(response);
                      if (typeof items !== "object") {
                        if (items === "JSONNull") {
                          return Promise.resolve({
                                      TAG: "Ok",
                                      _0: []
                                    });
                        }
                        throw {
                              RE_EXN_ID: UnexpectedAPIPayload,
                              _1: "Expected a list of items.",
                              Error: new Error()
                            };
                      } else {
                        switch (items.TAG) {
                          case "JSONObject" :
                              var parsed = Deserializer.fromJSON(response);
                              if (parsed.TAG === "Ok") {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: [parsed._0]
                                          });
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse the result from the API endpoint: " + parsed._0,
                                    Error: new Error()
                                  };
                          case "JSONArray" :
                              var items$1 = items._0;
                              if (items$1.length === 0) {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: []
                                          });
                              }
                              var parsed$1 = Prelude.ManyResults.bail(items$1.map(Deserializer.fromJSON));
                              if (parsed$1.TAG === "Ok") {
                                return Promise.resolve({
                                            TAG: "Ok",
                                            _0: parsed$1._0
                                          });
                              }
                              throw {
                                    RE_EXN_ID: UnexpectedAPIPayload,
                                    _1: "Could not parse some item in the list of the API endpoint: " + parsed$1._0,
                                    Error: new Error()
                                  };
                          default:
                            throw {
                                  RE_EXN_ID: UnexpectedAPIPayload,
                                  _1: "Expected a list of items.",
                                  Error: new Error()
                                };
                        }
                      }
                    })), (function (e) {
                  React.captureException(e);
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var _getMany = function (params, token, body, ids) {
    if (ids === undefined) {
      return _getManyPage(undefined, params, token, body);
    }
    var chunks = Prelude.$$Array.chunks(ids, 45);
    return Prelude.PromisedResult.map(Prelude.PromisedResult.bail(chunks.map(function (ids) {
                        return function () {
                          return _getManyPage(ids, params, token, undefined);
                        };
                      })), (function (results) {
                  return Curry._1(Prelude.$$Array.concatMany, results);
                }));
  };
  var getMany = function (params, token, ids) {
    return _getMany(params, token, undefined, ids);
  };
  var getAll = function (params, token) {
    return _getMany(params, token, undefined, undefined);
  };
  var getAllWithPost = function (params, body, token) {
    return _getMany(params, token, body, undefined);
  };
  var get = function (params, token, id) {
    var promise;
    if (url$1.endsWith("/:id")) {
      var params$1 = Object.assign(Prelude.default(params, {}), {
            id: id
          });
      promise = _getManyPage(undefined, Caml_option.some(params$1), token, undefined);
    } else {
      promise = _getManyPage([id], params, token, undefined);
    }
    return Prelude.catchResolve(Prelude.PromisedResult.map(promise, (function (result) {
                      var len = result.length;
                      if (len === 1) {
                        return Caml_option.some(result[0]);
                      }
                      if (len === 0) {
                        return ;
                      }
                      throw {
                            RE_EXN_ID: UnexpectedAPIPayload,
                            _1: "Got more than one item, expecting 0 or 1",
                            Error: new Error()
                          };
                    })), (function (e) {
                  return {
                          TAG: "Error",
                          _0: e
                        };
                }));
  };
  var options = function (params, token) {
    var request = MithrilRequest.payload(MithrilRequest.options(url$1), params);
    var request$1 = withAuth(request, token);
    return MithrilRequest.$$do(request$1);
  };
  var Impl = {
    Deserializer: Deserializer,
    UnexpectedAPIPayload: UnexpectedAPIPayload,
    getMany: getMany,
    getAll: getAll,
    getAllWithPost: getAllWithPost,
    get: get,
    options: options
  };
  var $$fetch = async function (token, params, pageOpt, pageSize, body) {
    var page = pageOpt !== undefined ? pageOpt : 1;
    var params$1 = Object.assign(Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, params, (function (prim) {
                    return prim;
                  })), {}), {
          page: page,
          page_size: Prelude.default(pageSize, Settings.Pagination.defaultSize)
        });
    var res = body !== undefined ? await getAllWithPost(Caml_option.some(params$1), Caml_option.some(Caml_option.valFromOption(body)), Caml_option.some(token)) : await getAll(Caml_option.some(params$1), Caml_option.some(token));
    if (res.TAG !== "Ok") {
      return {
              TAG: "Error",
              _0: res._0
            };
    }
    var result = res._0;
    if (result.length !== 0) {
      return {
              TAG: "Ok",
              _0: result[0]
            };
    } else {
      return {
              TAG: "Ok",
              _0: $$default
            };
    }
  };
  return {
          url: url,
          Deserializer: D.Deserializer,
          $$default: $$default,
          Impl: Impl,
          $$fetch: $$fetch
        };
}

export {
  BearerAuthenticator ,
  MakeReadonlyAPIEndpoint ,
  MakeBackendReadonlyAPIEndpoint ,
  MakeAPIEndpoint ,
  MakeBackendAPIEndpoint ,
  MakePaginatedEndpoint ,
}
/* mithril Not a pure module */
