// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as UserContext from "../../../global/context/UserContext.js";
import * as ReactI18Next from "../../../../libs/i18n/ReactI18Next.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as JsxRuntime from "react/jsx-runtime";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";

function MakeAutocomplete(funarg) {
  var getValueName = funarg.getValueName;
  var getValueKey = funarg.getValueKey;
  var makeSolo = funarg.makeSolo;
  var defaultPageSize = funarg.defaultPageSize;
  var $$fetch = funarg.$$fetch;
  var $$do = async function (arg) {
    var pageSize = Prelude.default(arg.pageSize, funarg.defaultPageSize);
    var error = await $$fetch(arg.token, Caml_option.some(arg.filters), 1, pageSize, undefined);
    if (error.TAG === "Ok") {
      var match = error._0;
      return {
              TAG: "Ok",
              _0: {
                items: match.results,
                hasMore: match.count > defaultPageSize
              }
            };
    }
    console.error("SearchMetaMaker", funarg.identifier, error._0);
    return {
            TAG: "Ok",
            _0: {
              items: [],
              hasMore: false
            }
          };
  };
  var defaultNotFoundMessage = JsxRuntime.jsx(ReactI18Next.Message.make, {
        msg: {
          NAME: "msg",
          VAL: [
            "autocomplete.nothing-found-message",
            "Nothing found"
          ]
        }
      });
  var defaultPromptMessage = JsxRuntime.jsx(ReactI18Next.Message.make, {
        msg: {
          NAME: "msg",
          VAL: [
            "autocomplete.prompt",
            "Write something to start filtering"
          ]
        }
      });
  var defaultLoadingMessage = JsxRuntime.jsx(ReactI18Next.Message.make, {
        msg: {
          NAME: "msg",
          VAL: [
            "autocomplete.loading",
            "Loading..."
          ]
        }
      });
  var defaultRenderOption = function (props, value, param, param$1) {
    var newrecord = Caml_obj.obj_dup(props);
    return JsxRuntime.jsxs("li", (newrecord.children = [
                  JsxRuntime.jsx(Checkbox, {
                        style: {
                          marginRight: "0.75rem"
                        },
                        checked: param.selected,
                        checkedIcon: Caml_option.some(JsxRuntime.jsx(CheckBox, {
                                  fontSize: "small"
                                })),
                        icon: Caml_option.some(JsxRuntime.jsx(CheckBoxOutlineBlank, {
                                  fontSize: "small"
                                }))
                      }),
                  getValueName(value)
                ], newrecord), getValueKey(value));
  };
  var SearchMetaMaker$MakeAutocomplete = function (props) {
    var helperText = props.helperText;
    var __autoSelect = props.autoSelect;
    var __freeSolo = props.freeSolo;
    var pageSize = props.pageSize;
    var placeholder = props.placeholder;
    var variant = props.variant;
    var label = props.label;
    var renderTag = props.renderTag;
    var getFilter = props.getFilter;
    var onChange = props.onChange;
    var items = props.items;
    var freeSolo = __freeSolo !== undefined ? __freeSolo : false;
    var autoSelect = __autoSelect !== undefined ? __autoSelect : false;
    var freeSolo$1 = freeSolo && Curry._1(Prelude.OptionExported.$$Option.isNone, makeSolo) ? (console.error("SearchMetaMaker", funarg.identifier, "cannot be freeSolo when the component has no makeSolo"), false) : freeSolo;
    var match = React.useState(function () {
          return items;
        });
    var setInternalItems = match[1];
    var internalItems = match[0];
    React.useEffect((function () {
            console.log("SearchMetaMaker", funarg.identifier, "useEffect setInternalItems", {
                  items: items,
                  internalItems: internalItems,
                  "is?": Object.is(items, internalItems)
                });
            if (!Object.is(items, internalItems)) {
              setInternalItems(function (param) {
                    return items;
                  });
            }
            
          }), [items]);
    var match$1 = React.useState(function () {
          return false;
        });
    var setLoading = match$1[1];
    var match$2 = React.useState(function () {
          return [];
        });
    var setOptions = match$2[1];
    var options = match$2[0];
    var match$3 = React.useState(function () {
          return false;
        });
    var setOpen = match$3[1];
    var open_ = match$3[0];
    var match$4 = React.useState(function () {
          return Prelude.default(funarg.notFoundMessage, defaultNotFoundMessage);
        });
    var setNoOptionsText = match$4[1];
    var token = Curry._1(Prelude.OptionExported.$$Option.getExn, UserContext.useAccessToken());
    var match$5 = React.useState(function () {
          return "";
        });
    var setInputValue = match$5[1];
    var inputValue = match$5[0];
    var query = React.useMemo((function () {
            return getFilter(inputValue);
          }), [
          getFilter,
          inputValue
        ]);
    var multiple = Prelude.default(props.multiple, true);
    var existingKeys = React.useMemo((function () {
            return Belt_SetString.fromArray(internalItems.map(getValueKey));
          }), [internalItems]);
    var existingNames = React.useMemo((function () {
            return Belt_SetString.fromArray(internalItems.map(getValueName));
          }), [internalItems]);
    var match$6 = React.useState(function () {
          
        });
    var setTimer = match$6[1];
    var timer = match$6[0];
    var clearTimer = React.useCallback((function () {
            if (timer !== undefined) {
              clearTimeout(Caml_option.valFromOption(timer));
              return ;
            }
            
          }), [
          timer,
          setTimer
        ]);
    React.useEffect((function () {
            clearTimer();
            setOptions(function (param) {
                  return [];
                });
            var tid = setTimeout((function () {
                    if (open_ && inputValue.length > 0) {
                      setLoading(function (param) {
                            return true;
                          });
                      setNoOptionsText(function (param) {
                            return Prelude.default(funarg.notFoundMessage, defaultNotFoundMessage);
                          });
                      return Prelude.thenDo($$do({
                                        token: token,
                                        filters: query,
                                        pageSize: pageSize
                                      }).finally(function () {
                                      setLoading(function (param) {
                                            return false;
                                          });
                                    }), (function (result) {
                                    if (result.TAG === "Ok") {
                                      var matches = Curry._2(Prelude.$$Array.keep, result._0.items, (function (item) {
                                              if (Belt_SetString.has(existingKeys, getValueKey(item))) {
                                                return false;
                                              } else {
                                                return !Belt_SetString.has(existingNames, getValueName(item));
                                              }
                                            }));
                                      return setOptions(function (param) {
                                                  return Prelude.$$Array.sort(Curry._2(Prelude.$$Array.concat, matches, internalItems), (function (v) {
                                                                return (getValueName(v) + getValueKey(v)).toLocaleLowerCase();
                                                              }), undefined);
                                                });
                                    }
                                    var msg = result._0;
                                    setOptions(function (param) {
                                          return [];
                                        });
                                    setNoOptionsText(function (param) {
                                          return JsxRuntime.jsx(ReactI18Next.Message.make, {
                                                      msg: msg
                                                    });
                                        });
                                  }));
                    } else {
                      return setNoOptionsText(function (param) {
                                  return Prelude.default(funarg.promptMessage, defaultPromptMessage);
                                });
                    }
                  }), 300);
            setTimer(function (param) {
                  return Caml_option.some(tid);
                });
            return clearTimer;
          }), [
          open_,
          query,
          internalItems,
          existingKeys,
          pageSize
        ]);
    var renderTags = React.useMemo((function () {
            return Curry._2(Prelude.OptionExported.$$Option.map, renderTag, (function (fn) {
                          return function (items, renderGetTagProps, param) {
                            return items.map(function (item, index) {
                                        var newrecord = Caml_obj.obj_dup(fn(item, Curry._1(renderGetTagProps, {
                                                      index: index
                                                    })));
                                        return JsxRuntime.jsx(Chip, (newrecord.label = Caml_option.some(getValueName(item)), newrecord), getValueKey(item) + "-" + String(index));
                                      });
                          };
                        }));
          }), [renderTag]);
    var renderOption = Prelude.default(funarg.renderOption, defaultRenderOption);
    var disableCloseOnSelect = Prelude.default(props.disableCloseOnSelect, Curry._1(Prelude.OptionExported.$$Option.isNone, funarg.renderOption));
    var multiple$1 = !multiple && Curry._1(Prelude.OptionExported.$$Option.isNone, funarg.defaultValue) ? (console.error("SearchMetaMaker", funarg.identifier, "cannot be multiple=false without a default value"), true) : multiple;
    var value = multiple$1 ? internalItems : Prelude.default(Prelude.$$Array.first(internalItems), Curry._1(Prelude.OptionExported.$$Option.getExn, funarg.defaultValue));
    var getOptionLabel = React.useMemo((function () {
            if (freeSolo$1) {
              return function (arg) {
                if (typeof arg === "undefined") {
                  return "";
                }
                if (typeof arg !== "string") {
                  return getValueName(arg);
                }
                var fn = Curry._1(Prelude.OptionExported.$$Option.getExn, makeSolo);
                return getValueName(fn(arg));
              };
            } else {
              return getValueName;
            }
          }), [freeSolo$1]);
    var onChangeInputValue = function (ev) {
      setInputValue(function (param) {
            return ev.target.value;
          });
    };
    var onChangeInternal = React.useMemo((function () {
            if (!freeSolo$1) {
              if (multiple$1) {
                return function (param, values, reason, param$1) {
                  console.log("SearchMetaMaker", {
                        values: values,
                        reason: reason
                      });
                  switch (reason) {
                    case "createOption" :
                    case "blur" :
                        return ;
                    default:
                      onChange(values);
                      setInternalItems(function (param) {
                            return values;
                          });
                      return setInputValue(function (param) {
                                  return "";
                                });
                  }
                };
              } else {
                return function (param, value, reason, param$1) {
                  console.log("SearchMetaMaker", {
                        value: value,
                        reason: reason
                      });
                  switch (reason) {
                    case "selectOption" :
                        onChange([value]);
                        return setInternalItems(function (param) {
                                    return [value];
                                  });
                    case "clear" :
                        onChange([]);
                        return setInternalItems(function (param) {
                                    return [];
                                  });
                    case "createOption" :
                    case "removeOption" :
                    case "blur" :
                        return ;
                    
                  }
                };
              }
            }
            var makeSolo$1 = Curry._1(Prelude.OptionExported.$$Option.getExn, makeSolo);
            if (multiple$1) {
              return function (ev, values, reason, param) {
                var match;
                switch (reason) {
                  case "createOption" :
                      var match$1 = options.find(function (p) {
                            return getOptionLabel(p) === inputValue;
                          });
                      match = match$1 !== undefined ? [
                          internalItems,
                          ""
                        ] : [
                          Curry._2(Prelude.$$Array.concat, internalItems, [makeSolo$1(inputValue)]),
                          ""
                        ];
                      break;
                  case "selectOption" :
                      var items = Curry._2(Prelude.$$Array.keepMap, values, (function (v) {
                              if (typeof v === "string") {
                                return Caml_option.some(makeSolo$1(v));
                              } else if (typeof v === "undefined") {
                                return ;
                              } else {
                                return Caml_option.some(v);
                              }
                            }));
                      match = [
                        items,
                        ""
                      ];
                      break;
                  case "removeOption" :
                      if (ev.type === "click" || inputValue === "") {
                        match = [
                          values,
                          ""
                        ];
                      } else {
                        var v = makeSolo$1(inputValue);
                        var match$2 = Curry._2(Prelude.$$Array.concat, options, internalItems).find(function (p) {
                              return getOptionLabel(p) === getOptionLabel(v);
                            });
                        match = match$2 !== undefined ? [
                            internalItems,
                            inputValue
                          ] : [
                            Curry._2(Prelude.$$Array.concat, internalItems, [v]),
                            ""
                          ];
                      }
                      break;
                  case "clear" :
                      match = [
                        [],
                        ""
                      ];
                      break;
                  case "blur" :
                      match = [
                        internalItems,
                        inputValue
                      ];
                      break;
                  
                }
                var internalValue = match[1];
                var values$1 = match[0];
                setInputValue(function (param) {
                      return internalValue;
                    });
                onChange(values$1);
                setInternalItems(function (param) {
                      return values$1;
                    });
              };
            } else {
              return function (param, value, reason, param$1) {
                switch (reason) {
                  case "removeOption" :
                  case "clear" :
                      break;
                  default:
                    if (typeof value === "string") {
                      var value$1 = makeSolo$1(value);
                      onChange([value$1]);
                      setInternalItems(function (param) {
                            return [value$1];
                          });
                    } else if (typeof value === "undefined") {
                      onChange([]);
                      setInternalItems(function (param) {
                            return [];
                          });
                    } else {
                      var v = makeSolo$1(getValueName(value));
                      onChange([v]);
                      setInternalItems(function (param) {
                            return [v];
                          });
                      setInputValue(function (param) {
                            return getOptionLabel(v);
                          });
                    }
                    return setOpen(function (param) {
                                return false;
                              });
                }
                onChange([]);
                setInternalItems(function (param) {
                      return [];
                    });
                setInputValue(function (param) {
                      return "";
                    });
              };
            }
          }), [
          multiple$1,
          onChange,
          setInternalItems,
          freeSolo$1,
          inputValue,
          options
        ]);
    return JsxRuntime.jsx(Autocomplete, {
                options: options,
                renderInput: (function (params) {
                    var newrecord = Caml_obj.obj_dup(params);
                    return JsxRuntime.jsx(TextField, (newrecord.variant = variant, newrecord.value = inputValue, newrecord.placeholder = placeholder, newrecord.onChange = onChangeInputValue, newrecord.label = label, newrecord.helperText = helperText, newrecord));
                  }),
                autoSelect: autoSelect,
                disableCloseOnSelect: disableCloseOnSelect,
                disabled: props.disabled,
                filterOptions: (function (x, param) {
                    return x;
                  }),
                freeSolo: freeSolo$1,
                fullWidth: props.fullWidth,
                getOptionLabel: getOptionLabel,
                groupBy: funarg.groupBy,
                inputValue: freeSolo$1 && !multiple$1 && inputValue === "" ? getValueName(value) : inputValue,
                isOptionEqualToValue: freeSolo$1 ? (function (item, val) {
                      if (getValueKey(item) === getValueKey(val)) {
                        return getOptionLabel(item) === getOptionLabel(val);
                      } else {
                        return false;
                      }
                    }) : (function (item, val) {
                      return getValueKey(item) === getValueKey(val);
                    }),
                limitTags: props.limitTags,
                loading: match$1[0],
                loadingText: Caml_option.some(defaultLoadingMessage),
                multiple: multiple$1,
                noOptionsText: Caml_option.some(match$4[0]),
                onChange: onChangeInternal,
                onClose: (function (param, param$1) {
                    setOpen(function (param) {
                          return false;
                        });
                  }),
                onInputChange: props.onInputChange,
                onOpen: (function (param) {
                    setOpen(function (param) {
                          return true;
                        });
                  }),
                open: open_,
                renderOption: renderOption,
                renderTags: renderTags,
                sx: props.sx,
                value: Caml_option.some(value)
              });
  };
  return {
          getValueName: getValueName,
          getValueKey: getValueKey,
          $$fetch: $$fetch,
          make: SearchMetaMaker$MakeAutocomplete
        };
}

export {
  MakeAutocomplete ,
}
/* react Not a pure module */
