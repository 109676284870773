// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as JsxRuntime from "react/jsx-runtime";

function Make(M) {
  var Id = M.Id;
  var scope = M.scope;
  var getItemId = M.getItemId;
  var context = React.createContext(undefined);
  var make = context.Provider;
  var Provider = {
    make: make
  };
  var State = {
    context: context,
    Provider: Provider
  };
  var context$1 = React.createContext(undefined);
  var make$1 = context$1.Provider;
  var Provider$1 = {
    make: make$1
  };
  var Dispatchers = {
    context: context$1,
    Provider: Provider$1
  };
  var useGetCurrentSelection = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                  return param.getCurrentSelection;
                }));
  };
  var useGetCurrentSelectionIds = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                  return param.getCurrentSelectionIds;
                }));
  };
  var useIsSelected = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                  return param.isSelected;
                }));
  };
  var useAreAllSelected = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                  return param.areAllSelected;
                }));
  };
  var useHasSelection = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context), (function (param) {
                  return param.hasSelection;
                }));
  };
  var useSelect = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                  return param.select;
                }));
  };
  var useUnselect = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                  return param.unselect;
                }));
  };
  var useToggle = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                  return param.toggle;
                }));
  };
  var useReset = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                  return param.reset;
                }));
  };
  var useBegin = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                  return param.begin;
                }));
  };
  var useCommit = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                  return param.commit;
                }));
  };
  var useRollback = function () {
    return Curry._2(Prelude.OptionExported.$$Option.map, React.useContext(context$1), (function (param) {
                  return param.rollback;
                }));
  };
  var SelectionManagerMaker$UUID$Make = function (props) {
    var match = React.useReducer((function (state, action) {
            if (typeof action !== "object") {
              switch (action) {
                case "Reset" :
                    if (state.TAG === "Realtime") {
                      return {
                              TAG: "Realtime",
                              _0: [
                                Id.$$Map.make(),
                                false
                              ]
                            };
                    } else {
                      return {
                              TAG: "Transaction",
                              previous: state.previous,
                              working: [
                                Id.$$Map.make(),
                                false
                              ]
                            };
                    }
                case "Begin" :
                    if (state.TAG === "Realtime") {
                      var previous = state._0;
                      return {
                              TAG: "Transaction",
                              previous: previous,
                              working: previous
                            };
                    }
                    console.error("SelectionManagerMaker", "Cannot start a transaction", state);
                    return state;
                case "Commit" :
                    if (state.TAG !== "Realtime") {
                      return {
                              TAG: "Realtime",
                              _0: state.working
                            };
                    }
                    console.error("SelectionManagerMaker", "Cannot commit outside a transaction", state);
                    return state;
                case "Rollback" :
                    if (state.TAG !== "Realtime") {
                      return {
                              TAG: "Realtime",
                              _0: state.previous
                            };
                    }
                    console.error("SelectionManagerMaker", "Cannot rollback outside a transaction", state);
                    return state;
                
              }
            } else {
              switch (action.TAG) {
                case "Select" :
                    var instances = action._0;
                    if (state.TAG === "Realtime") {
                      var result = {
                        contents: Id.$$Map.copy(state._0[0])
                      };
                      Curry._2(Prelude.$$Array.forEach, Curry._2(Prelude.$$Array.keep, instances, M.canBeSelected), (function (item) {
                              result.contents = Id.$$Map.set(result.contents, getItemId(item), item);
                            }));
                      return {
                              TAG: "Realtime",
                              _0: [
                                result.contents,
                                !Id.$$Map.isEmpty(result.contents)
                              ]
                            };
                    }
                    var result$1 = {
                      contents: Id.$$Map.copy(state.working[0])
                    };
                    Curry._2(Prelude.$$Array.forEach, Curry._2(Prelude.$$Array.keep, instances, M.canBeSelected), (function (item) {
                            result$1.contents = Id.$$Map.set(result$1.contents, getItemId(item), item);
                          }));
                    return {
                            TAG: "Transaction",
                            previous: state.previous,
                            working: [
                              result$1.contents,
                              !Id.$$Map.isEmpty(result$1.contents)
                            ]
                          };
                case "Unselect" :
                    var instances$1 = action._0;
                    if (state.TAG === "Realtime") {
                      var result$2 = {
                        contents: Id.$$Map.copy(state._0[0])
                      };
                      Curry._2(Prelude.$$Array.forEach, Curry._2(Prelude.$$Array.keep, instances$1, M.canBeSelected), (function (item) {
                              result$2.contents = Id.$$Map.remove(result$2.contents, getItemId(item));
                            }));
                      return {
                              TAG: "Realtime",
                              _0: [
                                result$2.contents,
                                !Id.$$Map.isEmpty(result$2.contents)
                              ]
                            };
                    }
                    var result$3 = {
                      contents: Id.$$Map.copy(state.working[0])
                    };
                    Curry._2(Prelude.$$Array.forEach, Curry._2(Prelude.$$Array.keep, instances$1, M.canBeSelected), (function (item) {
                            result$3.contents = Id.$$Map.remove(result$3.contents, getItemId(item));
                          }));
                    return {
                            TAG: "Transaction",
                            previous: state.previous,
                            working: [
                              result$3.contents,
                              !Id.$$Map.isEmpty(result$3.contents)
                            ]
                          };
                case "Toggle" :
                    var instances$2 = action._0;
                    if (state.TAG === "Realtime") {
                      var result$4 = {
                        contents: Id.$$Map.copy(state._0[0])
                      };
                      Curry._2(Prelude.$$Array.forEach, Curry._2(Prelude.$$Array.keep, instances$2, M.canBeSelected), (function (item) {
                              var key = getItemId(item);
                              if (Id.$$Map.has(result$4.contents, key)) {
                                result$4.contents = Id.$$Map.remove(result$4.contents, key);
                              } else {
                                result$4.contents = Id.$$Map.set(result$4.contents, key, item);
                              }
                            }));
                      return {
                              TAG: "Realtime",
                              _0: [
                                result$4.contents,
                                !Id.$$Map.isEmpty(result$4.contents)
                              ]
                            };
                    }
                    var result$5 = {
                      contents: Id.$$Map.copy(state.working[0])
                    };
                    Curry._2(Prelude.$$Array.forEach, instances$2, (function (item) {
                            var key = getItemId(item);
                            if (Id.$$Map.has(result$5.contents, key)) {
                              result$5.contents = Id.$$Map.remove(result$5.contents, key);
                            } else {
                              result$5.contents = Id.$$Map.set(result$5.contents, key, item);
                            }
                          }));
                    return {
                            TAG: "Transaction",
                            previous: state.previous,
                            working: [
                              result$5.contents,
                              !Id.$$Map.isEmpty(result$5.contents)
                            ]
                          };
                
              }
            }
          }), {
          TAG: "Realtime",
          _0: [
            Id.$$Map.make(),
            false
          ]
        });
    var dispatch = match[1];
    var state = match[0];
    var select = React.useCallback((function (items) {
            dispatch({
                  TAG: "Select",
                  _0: items
                });
          }), [dispatch]);
    var unselect = React.useCallback((function (items) {
            dispatch({
                  TAG: "Unselect",
                  _0: items
                });
          }), [dispatch]);
    var toggle = React.useCallback((function (items) {
            dispatch({
                  TAG: "Toggle",
                  _0: items
                });
          }), [dispatch]);
    var reset = React.useCallback((function () {
            dispatch("Reset");
          }), [dispatch]);
    var begin = React.useCallback((function () {
            dispatch("Begin");
          }), [dispatch]);
    var commit = React.useCallback((function () {
            dispatch("Commit");
          }), [dispatch]);
    var rollback = React.useCallback((function () {
            dispatch("Rollback");
          }), [dispatch]);
    var getCurrentSelection = React.useCallback((function () {
            if (state.TAG === "Realtime") {
              return state._0[0];
            } else {
              return state.previous[0];
            }
          }), [state]);
    var hasSelection = React.useMemo((function () {
            if (state.TAG === "Realtime") {
              return state._0[1];
            } else {
              return state.previous[1];
            }
          }), [state]);
    var getCurrentSelectionIds = React.useCallback((function () {
            return Id.$$Set.fromArray(Id.$$Map.keysToArray(getCurrentSelection()));
          }), [getCurrentSelection]);
    var isSelected = React.useCallback((function (item) {
            return Id.$$Map.has(getCurrentSelection(), getItemId(item));
          }), [getCurrentSelection]);
    var areAllSelected = React.useCallback((function (items) {
            var ids = Id.$$Set.fromArray(items.map(getItemId));
            return Id.$$Set.subset(ids, getCurrentSelectionIds());
          }), [
          state,
          getCurrentSelectionIds
        ]);
    var doubled = React.useContext(context);
    if (doubled !== undefined) {
      console.warn("SelectionManagerMaker", scope, "has already setup, you might shadowing it.");
    }
    var state$1 = {
      getCurrentSelection: getCurrentSelection,
      getCurrentSelectionIds: getCurrentSelectionIds,
      isSelected: isSelected,
      areAllSelected: areAllSelected,
      hasSelection: hasSelection
    };
    var dispatchers = {
      select: select,
      unselect: unselect,
      toggle: toggle,
      reset: reset,
      begin: begin,
      commit: commit,
      rollback: rollback
    };
    return JsxRuntime.jsx(make, {
                value: state$1,
                children: JsxRuntime.jsx(make$1, {
                      value: dispatchers,
                      children: props.children
                    })
              });
  };
  return {
          Model: M.Model,
          Id: Id,
          scope: scope,
          getItemId: getItemId,
          canBeSelected: M.canBeSelected,
          State: State,
          Dispatchers: Dispatchers,
          useGetCurrentSelection: useGetCurrentSelection,
          useGetCurrentSelectionIds: useGetCurrentSelectionIds,
          useIsSelected: useIsSelected,
          useAreAllSelected: useAreAllSelected,
          useHasSelection: useHasSelection,
          useSelect: useSelect,
          useUnselect: useUnselect,
          useToggle: useToggle,
          useReset: useReset,
          useBegin: useBegin,
          useCommit: useCommit,
          useRollback: useRollback,
          make: SelectionManagerMaker$UUID$Make
        };
}

var UUID = {
  Make: Make
};

export {
  UUID ,
}
/* react Not a pure module */
