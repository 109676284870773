// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FailurePage from "../components/pages/FailurePage.js";
import * as React$1 from "@sentry/react";
import * as SharedDBQuery from "../db/SharedDBQuery.js";
import * as ResetAppButton from "../components/ResetAppButton.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function default_read(param) {
  return Promise.resolve(SharedDBQuery.makeResponse());
}

function default_write(param) {
  return Promise.resolve(SharedDBQuery.makeResponse());
}

function default_do(param) {
  return Promise.resolve(SharedDBQuery.makeResponse());
}

var $$default = {
  dbname: "",
  makeRead: SharedDBQuery.makeRead,
  makeWrite: SharedDBQuery.makeWrite,
  makeResponse: SharedDBQuery.makeResponse,
  read: default_read,
  write: default_write,
  do: default_do
};

var context = React.createContext($$default);

var make = context.Provider;

var Provider = {
  make: make
};

var Context = {
  $$default: $$default,
  context: context,
  Provider: Provider
};

function useQuery() {
  return React.useContext(context);
}

var InitializationError = /* @__PURE__ */Caml_exceptions.create("SharedDBContext.Migrate.InitializationError");

function SharedDBContext$Migrate(props) {
  var onDone = props.onDone;
  var dbname = props.dbname;
  var match = React.useState(function () {
        return "InitDB";
      });
  var setState = match[1];
  var init = async function () {
    var result;
    try {
      result = await SharedDBQuery.Database.connect(dbname);
    }
    catch (raw_error){
      var error = Caml_js_exceptions.internalToOCamlException(raw_error);
      console.error("Could not migrate", error);
      React$1.captureException(error);
      return setState(function (param) {
                  return "ErrorInitDB";
                });
    }
    if (result.TAG === "Ok") {
      var db = result._0;
      Curry._2(Prelude.OptionExported.$$Option.map, onDone, (function (fn) {
              fn({
                    TAG: "Ok",
                    _0: db
                  });
            }));
      return ;
    }
    var error$1 = result._0;
    console.error("Could not migrate", dbname, error$1);
    React$1.captureException(error$1);
    setState(function (param) {
          return "ErrorInitDB";
        });
    Curry._2(Prelude.OptionExported.$$Option.map, onDone, (function (fn) {
            fn({
                  TAG: "Error",
                  _0: error$1
                });
          }));
  };
  React.useEffect((function () {
          init();
        }), []);
  return null;
}

var Migrate = {
  InitializationError: InitializationError,
  IntMap: undefined,
  make: SharedDBContext$Migrate
};

function SharedDBContext(props) {
  var match = React.useState(function () {
        return "InitDB";
      });
  var setState = match[1];
  var state = match[0];
  var dbname = "photocuba-shared-db";
  if (typeof state !== "object") {
    switch (state) {
      case "InitDB" :
          return JsxRuntime.jsx(SharedDBContext$Migrate, {
                      dbname: dbname,
                      onDone: (function (result) {
                          if (result.TAG !== "Ok") {
                            return setState(function (param) {
                                        return "ErrorInitDB";
                                      });
                          }
                          var db = result._0;
                          setState(function (param) {
                                return {
                                        TAG: "DoneInitDB",
                                        _0: db
                                      };
                              });
                        })
                    });
      case "ErrorInitDB" :
          var reasons = [
            {
              NAME: "msg",
              VAL: [
                "init.db.error.description.l1",
                "There was an error while initializing the\n         application's database.  Unfortunately, this means that the database is\n         possibly corrupt and we need to reset it."
              ]
            },
            {
              NAME: "msg",
              VAL: [
                "init.db.error.description.l2",
                "This will remove all the local copies of the application's data from\n         the browser, reinitialize the application and then fetch it from\n         the server."
              ]
            },
            {
              NAME: "msg",
              VAL: [
                "init.db.error.description.l3",
                "For this reason, you need to be online and it might take some time\n         for the data to be fetched."
              ]
            }
          ];
          return JsxRuntime.jsx(FailurePage.make, {
                      title: {
                        NAME: "str",
                        VAL: "Initialization error"
                      },
                      reasons: reasons,
                      children: Caml_option.some(JsxRuntime.jsx(ResetAppButton.make, {
                                dbnames: [dbname]
                              }))
                    });
      case "DeveloperResetDB" :
          var reasons$1 = [
            {
              NAME: "msg",
              VAL: [
                "init.db.reset.request",
                "You have requested to reset the local DB.  I'm sure you have your\n         reasons to do it and you know what you're doing, right?"
              ]
            },
            {
              NAME: "msg",
              VAL: [
                "init.db.reset.tabs",
                "Please, close all other tabs you might have opened to ensure the local DB\n         can be deleted."
              ]
            }
          ];
          return JsxRuntime.jsx(FailurePage.make, {
                      title: {
                        NAME: "str",
                        VAL: "Resetting the DB"
                      },
                      reasons: reasons$1,
                      children: Caml_option.some(JsxRuntime.jsx(ResetAppButton.make, {
                                dbnames: [dbname]
                              }))
                    });
      
    }
  } else {
    var db = state._0;
    var value_read = function (q) {
      return SharedDBQuery.read(db, q);
    };
    var value_write = function (q) {
      return SharedDBQuery.write(db, q);
    };
    var value_do = function (q) {
      return SharedDBQuery.$$do(db, q);
    };
    var value = {
      dbname: dbname,
      makeRead: SharedDBQuery.makeRead,
      makeWrite: SharedDBQuery.makeWrite,
      makeResponse: SharedDBQuery.makeResponse,
      read: value_read,
      write: value_write,
      do: value_do
    };
    return JsxRuntime.jsx(make, {
                value: value,
                children: props.children
              });
  }
}

var make$1 = SharedDBContext;

export {
  Context ,
  useQuery ,
  Migrate ,
  make$1 as make,
}
/* context Not a pure module */
