// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$URL from "../../../utils/URL.js";
import * as $$File from "./File.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Attribute from "./Attribute.js";
import * as ReIndexed from "@kaiko.io/rescript-reindexed/lib/es6/src/ReIndexed.js";
import * as DocumentId from "./DocumentId.js";
import * as Orientation from "./Orientation.js";

var Definition = {};

var include = ReIndexed.MakeIdModel(Definition, {});

function _getFiles($$document) {
  var res = [$$document.attachment];
  var match = $$document.previews;
  var tmp;
  if (match !== undefined) {
    var small = match.small;
    if (small !== undefined) {
      var large = match.large;
      tmp = large !== undefined ? Curry._2(Prelude.$$Array.concat, res, [
              small,
              large
            ]) : Curry._2(Prelude.$$Array.concat, res, [small]);
    } else {
      var large$1 = match.large;
      tmp = large$1 !== undefined ? Curry._2(Prelude.$$Array.concat, res, [large$1]) : res;
    }
  } else {
    tmp = res;
  }
  return tmp.map(function (f) {
              return $$File.source(f);
            });
}

function getFiles(documents) {
  return Prelude.$$Array.flatMap(documents, _getFiles);
}

function clone(folder) {
  return Object.assign({}, folder);
}

function getAttribute(doc, name) {
  return Prelude.$$Array.first(Curry._2(Prelude.$$Array.keep, doc.attributes, (function (attr) {
                    return attr.attribute_name === name;
                  })));
}

function MakeDenormalizer(N) {
  var denormalize = function (doc) {
    var attr = getAttribute(doc, N.attribute_name);
    if (attr === undefined) {
      return doc;
    }
    var denormalized = Prelude.default(doc.denormalized, {});
    var newrecord = Caml_obj.obj_dup(doc);
    newrecord.denormalized = N.assign(denormalized, attr.value);
    return newrecord;
  };
  return {
          denormalize: denormalize
        };
}

function MakeAttributeSetter(M) {
  var set = function ($$document, value) {
    var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
            return a.attribute_name !== M.attribute_name;
          }));
    var value$1 = M.toString(value);
    var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make(M.attribute_name, value$1));
    var newrecord = Caml_obj.obj_dup($$document);
    return M.denormalize((newrecord.attributes = attributes$1, newrecord));
  };
  var remove = function ($$document) {
    var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
            return a.attribute_name !== M.attribute_name;
          }));
    var newrecord = Caml_obj.obj_dup($$document);
    return M.denormalize((newrecord.attributes = attributes, newrecord));
  };
  return {
          set: set,
          remove: remove
        };
}

function assign(d, country) {
  var newrecord = Caml_obj.obj_dup(d);
  newrecord.country = country;
  return newrecord;
}

function denormalize(doc) {
  var attr = getAttribute(doc, "country");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign(denormalized, attr.value);
  return newrecord;
}

var Country = {
  denormalize: denormalize
};

function set($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "country";
        }));
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("country", value));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize((newrecord.attributes = attributes$1, newrecord));
}

function remove($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "country";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize((newrecord.attributes = attributes, newrecord));
}

var CountrySetter = {
  set: set,
  remove: remove
};

function assign$1(d, country_code) {
  var newrecord = Caml_obj.obj_dup(d);
  newrecord.country_code = country_code;
  return newrecord;
}

function denormalize$1(doc) {
  var attr = getAttribute(doc, "country_code");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign$1(denormalized, attr.value);
  return newrecord;
}

var CountryCode = {
  denormalize: denormalize$1
};

function set$1($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "country_code";
        }));
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("country_code", value));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$1((newrecord.attributes = attributes$1, newrecord));
}

function remove$1($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "country_code";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$1((newrecord.attributes = attributes, newrecord));
}

var CountryCodeSetter = {
  set: set$1,
  remove: remove$1
};

function assign$2(d, city) {
  var newrecord = Caml_obj.obj_dup(d);
  newrecord.city = city;
  return newrecord;
}

function denormalize$2(doc) {
  var attr = getAttribute(doc, "city");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign$2(denormalized, attr.value);
  return newrecord;
}

var City = {
  denormalize: denormalize$2
};

function set$2($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "city";
        }));
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("city", value));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$2((newrecord.attributes = attributes$1, newrecord));
}

function remove$2($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "city";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$2((newrecord.attributes = attributes, newrecord));
}

var CitySetter = {
  set: set$2,
  remove: remove$2
};

function assign$3(d, author) {
  var newrecord = Caml_obj.obj_dup(d);
  newrecord.author = author;
  return newrecord;
}

function denormalize$3(doc) {
  var attr = getAttribute(doc, "authors");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign$3(denormalized, attr.value);
  return newrecord;
}

var Author = {
  denormalize: denormalize$3
};

function set$3($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "authors";
        }));
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("authors", value));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$3((newrecord.attributes = attributes$1, newrecord));
}

function remove$3($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "authors";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$3((newrecord.attributes = attributes, newrecord));
}

var AuthorSetter = {
  set: set$3,
  remove: remove$3
};

function assign$4(d, copyright) {
  var newrecord = Caml_obj.obj_dup(d);
  newrecord.copyright = copyright;
  return newrecord;
}

function denormalize$4(doc) {
  var attr = getAttribute(doc, "copyright");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign$4(denormalized, attr.value);
  return newrecord;
}

var Copyright = {
  denormalize: denormalize$4
};

function set$4($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "copyright";
        }));
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("copyright", value));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$4((newrecord.attributes = attributes$1, newrecord));
}

function remove$4($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "copyright";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$4((newrecord.attributes = attributes, newrecord));
}

var CopyrightSetter = {
  set: set$4,
  remove: remove$4
};

function assign$5(d, $$event) {
  var newrecord = Caml_obj.obj_dup(d);
  newrecord.event = $$event;
  return newrecord;
}

function denormalize$5(doc) {
  var attr = getAttribute(doc, "event");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign$5(denormalized, attr.value);
  return newrecord;
}

var $$Event = {
  denormalize: denormalize$5
};

function set$5($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "event";
        }));
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("event", value));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$5((newrecord.attributes = attributes$1, newrecord));
}

function remove$5($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "event";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$5((newrecord.attributes = attributes, newrecord));
}

var EventSetter = {
  set: set$5,
  remove: remove$5
};

function assign$6(d, headline) {
  var newrecord = Caml_obj.obj_dup(d);
  newrecord.headline = headline;
  return newrecord;
}

function denormalize$6(doc) {
  var attr = getAttribute(doc, "headline");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign$6(denormalized, attr.value);
  return newrecord;
}

var Headline = {
  denormalize: denormalize$6
};

function set$6($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "headline";
        }));
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("headline", value));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$6((newrecord.attributes = attributes$1, newrecord));
}

function remove$6($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "headline";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$6((newrecord.attributes = attributes, newrecord));
}

var HeadlineSetter = {
  set: set$6,
  remove: remove$6
};

function assign$7(d, sources) {
  var newrecord = Caml_obj.obj_dup(d);
  newrecord.sources = sources;
  return newrecord;
}

function denormalize$7(doc) {
  var attr = getAttribute(doc, "sources");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign$7(denormalized, attr.value);
  return newrecord;
}

var Sources = {
  denormalize: denormalize$7
};

function set$7($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "sources";
        }));
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("sources", value));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$7((newrecord.attributes = attributes$1, newrecord));
}

function remove$7($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "sources";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$7((newrecord.attributes = attributes, newrecord));
}

var SourcesSetter = {
  set: set$7,
  remove: remove$7
};

function assign$8(d, usage_terms) {
  var newrecord = Caml_obj.obj_dup(d);
  newrecord.usage_terms = usage_terms;
  return newrecord;
}

function denormalize$8(doc) {
  var attr = getAttribute(doc, "usage_terms");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign$8(denormalized, attr.value);
  return newrecord;
}

var UsageTerms = {
  denormalize: denormalize$8
};

function set$8($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "usage_terms";
        }));
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("usage_terms", value));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$8((newrecord.attributes = attributes$1, newrecord));
}

function remove$8($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "usage_terms";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$8((newrecord.attributes = attributes, newrecord));
}

var UsageTermsSetter = {
  set: set$8,
  remove: remove$8
};

function assign$9(d, author_job_title) {
  var newrecord = Caml_obj.obj_dup(d);
  newrecord.author_job_title = author_job_title;
  return newrecord;
}

function denormalize$9(doc) {
  var attr = getAttribute(doc, "authors_job_title");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign$9(denormalized, attr.value);
  return newrecord;
}

var AuthorJobTitle = {
  denormalize: denormalize$9
};

function set$9($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "authors_job_title";
        }));
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("authors_job_title", value));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$9((newrecord.attributes = attributes$1, newrecord));
}

function remove$9($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "authors_job_title";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$9((newrecord.attributes = attributes, newrecord));
}

var AuthorJobTitleSetter = {
  set: set$9,
  remove: remove$9
};

function assign$10(denorm, value) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Orientation.Ifd0.fromString(value), (function (ifd0_orientation) {
                    var newrecord = Caml_obj.obj_dup(denorm);
                    newrecord.ifd0_orientation = ifd0_orientation;
                    return newrecord;
                  })), denorm);
}

function denormalize$10(doc) {
  var attr = getAttribute(doc, "ifd0_orientation");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign$10(denormalized, attr.value);
  return newrecord;
}

var Ifd0Orientation = {
  denormalize: denormalize$10
};

function set$10($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "ifd0_orientation";
        }));
  var value$1 = String(value);
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("ifd0_orientation", value$1));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$10((newrecord.attributes = attributes$1, newrecord));
}

function remove$10($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "ifd0_orientation";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$10((newrecord.attributes = attributes, newrecord));
}

var Ifd0OrientationSetter = {
  set: set$10,
  remove: remove$10
};

function assign$11(denorm, value) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.map, Orientation.Ifd0.fromString(value), (function (custom_ifd0_orientation) {
                    var newrecord = Caml_obj.obj_dup(denorm);
                    newrecord.custom_ifd0_orientation = custom_ifd0_orientation;
                    return newrecord;
                  })), denorm);
}

function denormalize$11(doc) {
  var attr = getAttribute(doc, "custom_ifd0_orientation");
  if (attr === undefined) {
    return doc;
  }
  var denormalized = Prelude.default(doc.denormalized, {});
  var newrecord = Caml_obj.obj_dup(doc);
  newrecord.denormalized = assign$11(denormalized, attr.value);
  return newrecord;
}

var CustomIfd0Orientation = {
  denormalize: denormalize$11
};

function set$11($$document, value) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "custom_ifd0_orientation";
        }));
  var value$1 = String(value);
  var attributes$1 = Prelude.$$Array.append(attributes, Attribute.Value.make("custom_ifd0_orientation", value$1));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$11((newrecord.attributes = attributes$1, newrecord));
}

function remove$11($$document) {
  var attributes = Curry._2(Prelude.$$Array.keep, $$document.attributes, (function (a) {
          return a.attribute_name !== "custom_ifd0_orientation";
        }));
  var newrecord = Caml_obj.obj_dup($$document);
  return denormalize$11((newrecord.attributes = attributes, newrecord));
}

var CustomIfd0OrientationSetter = {
  set: set$11,
  remove: remove$11
};

function denormalize$12(doc) {
  var height = Curry._2(Prelude.OptionExported.$$Option.flatMap, getAttribute(doc, "height"), (function (param) {
          return Curry._1(Prelude.Int.fromString, param.value);
        }));
  var denormalized = Prelude.default(doc.denormalized, {});
  var denormalized$1;
  if (height !== undefined) {
    var newrecord = Caml_obj.obj_dup(denormalized);
    newrecord.height = height;
    denormalized$1 = newrecord;
  } else {
    denormalized$1 = denormalized;
  }
  var width = Curry._2(Prelude.OptionExported.$$Option.flatMap, getAttribute(doc, "width"), (function (param) {
          return Curry._1(Prelude.Int.fromString, param.value);
        }));
  var denormalized$2;
  if (width !== undefined) {
    var newrecord$1 = Caml_obj.obj_dup(denormalized$1);
    newrecord$1.width = width;
    denormalized$2 = newrecord$1;
  } else {
    denormalized$2 = denormalized$1;
  }
  var denormalized$3;
  if (height !== undefined && width !== undefined) {
    var newrecord$2 = Caml_obj.obj_dup(denormalized$2);
    newrecord$2.dimensions = Math.imul(height, width);
    denormalized$3 = newrecord$2;
  } else {
    denormalized$3 = denormalized$2;
  }
  var newrecord$3 = Caml_obj.obj_dup(doc);
  newrecord$3.denormalized = denormalized$3;
  return newrecord$3;
}

var Dimensions = {
  denormalize: denormalize$12
};

var Denormalization = {
  getAttribute: getAttribute,
  MakeDenormalizer: MakeDenormalizer,
  MakeAttributeSetter: MakeAttributeSetter,
  Country: Country,
  CountrySetter: CountrySetter,
  CountryCode: CountryCode,
  CountryCodeSetter: CountryCodeSetter,
  City: City,
  CitySetter: CitySetter,
  Author: Author,
  AuthorSetter: AuthorSetter,
  Copyright: Copyright,
  CopyrightSetter: CopyrightSetter,
  $$Event: $$Event,
  EventSetter: EventSetter,
  Headline: Headline,
  HeadlineSetter: HeadlineSetter,
  Sources: Sources,
  SourcesSetter: SourcesSetter,
  UsageTerms: UsageTerms,
  UsageTermsSetter: UsageTermsSetter,
  AuthorJobTitle: AuthorJobTitle,
  AuthorJobTitleSetter: AuthorJobTitleSetter,
  Ifd0Orientation: Ifd0Orientation,
  Ifd0OrientationSetter: Ifd0OrientationSetter,
  CustomIfd0Orientation: CustomIfd0Orientation,
  CustomIfd0OrientationSetter: CustomIfd0OrientationSetter,
  Dimensions: Dimensions
};

function fromBackend(node) {
  var previews = {};
  var match = node.attachment.previews;
  var previews$1;
  if (match !== undefined) {
    var small = match.small;
    if (small !== undefined) {
      var newrecord = Caml_obj.obj_dup(previews);
      newrecord.small = $$File.fromBackend(small);
      previews$1 = newrecord;
    } else {
      previews$1 = previews;
    }
  } else {
    previews$1 = previews;
  }
  var match$1 = node.attachment.previews;
  var previews$2;
  if (match$1 !== undefined) {
    var large = match$1.large;
    if (large !== undefined) {
      var newrecord$1 = Caml_obj.obj_dup(previews$1);
      newrecord$1.large = $$File.fromBackend(large);
      previews$2 = newrecord$1;
    } else {
      previews$2 = previews$1;
    }
  } else {
    previews$2 = previews$1;
  }
  var result_id = node.id;
  var result_folder = node.parent;
  var result_namespace = node.namespace;
  var result_name = node.name;
  var result_created_at = node.created_at;
  var result_updated_at = node.updated_at;
  var result_attributes = Prelude.$$Array.flatMap(node.attributes, Attribute.PayloadOutput.map);
  var result_collections = node.collections;
  var result_attachment = $$File.fromBackend(node.attachment);
  var result_description = node.description;
  var result_previews = previews$2;
  var result_tags = node.tags;
  var result = {
    id: result_id,
    folder: result_folder,
    namespace: result_namespace,
    name: result_name,
    created_at: result_created_at,
    updated_at: result_updated_at,
    attributes: result_attributes,
    collections: result_collections,
    attachment: result_attachment,
    description: result_description,
    previews: result_previews,
    tags: result_tags
  };
  return denormalize$11(denormalize$10(denormalize$1(denormalize$9(denormalize$8(denormalize$7(denormalize$6(denormalize$5(denormalize$12(denormalize$4(denormalize$3(denormalize$2(denormalize(result)))))))))))));
}

function get($$document) {
  return Curry._2(Prelude.OptionExported.$$Option.map, Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                    return d.custom_ifd0_orientation;
                  })), (function (custom) {
                var initial = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                            return d.ifd0_orientation;
                          })), 1);
                return Orientation.Transformations.encode(Curry._2(Prelude.$$Array.concat, Curry._1(Prelude.$$Array.reverse, Orientation.Transformations.decode(initial)).map(Orientation.Transformations.negate), Orientation.Transformations.decode(custom)));
              }));
}

function set$12($$document, orientation) {
  var initial = Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
              return d.ifd0_orientation;
            })), 1);
  var orientation$1 = Orientation.Transformations.encode(Curry._2(Prelude.$$Array.concat, Orientation.Transformations.decode(initial), Orientation.Transformations.decode(orientation)));
  return set$11($$document, orientation$1);
}

function remove$12(d) {
  return set$12(d, 1);
}

var Orientation$1 = {
  Ifd0: undefined,
  get: get,
  set: set$12,
  remove: remove$12
};

function merge(source, target) {
  var previews = source.previews;
  var small = Curry._2(Prelude.OptionExported.$$Option.flatMap, previews, (function (p) {
          return p.small;
        }));
  var large = Curry._2(Prelude.OptionExported.$$Option.flatMap, previews, (function (p) {
          return p.large;
        }));
  var match = target.previews;
  if (match !== undefined) {
    var small$1 = match.small;
    if (small$1 !== undefined) {
      if (match.large !== undefined) {
        return target;
      }
      var newrecord = Caml_obj.obj_dup(target);
      newrecord.previews = {
        small: small$1,
        large: large
      };
      return newrecord;
    }
    var large$1 = match.large;
    if (large$1 !== undefined) {
      var newrecord$1 = Caml_obj.obj_dup(target);
      newrecord$1.previews = {
        small: small,
        large: large$1
      };
      return newrecord$1;
    }
    var newrecord$2 = Caml_obj.obj_dup(target);
    newrecord$2.previews = previews;
    return newrecord$2;
  }
  var newrecord$3 = Caml_obj.obj_dup(target);
  newrecord$3.previews = previews;
  return newrecord$3;
}

function fileName($$document) {
  var match = $$document.attachment;
  var variant = match.NAME;
  if (variant === "cached" || variant === "remote") {
    return Curry._2(Prelude.OptionExported.$$Option.flatMap, $$URL.fromString(match.VAL.url), (function (url) {
                  var pathname = $$URL.pathname($$URL.make(url));
                  return Prelude.$$Array.last(pathname.split("/"));
                }));
  }
  
}

function name($$document) {
  var name$1 = $$document.name.trim();
  if (name$1 === "") {
    return Prelude.default(fileName($$document), DocumentId.toString($$document.id));
  } else {
    return name$1;
  }
}

function author($$document) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                return d.author;
              }));
}

function copyright($$document) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                    return d.copyright;
                  })), "");
}

function country($$document) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                return d.country;
              }));
}

function city($$document) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                return d.city;
              }));
}

function $$event($$document) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                    return d.event;
                  })), "");
}

function headline($$document) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                    return d.headline;
                  })), "");
}

function sources($$document) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                    return d.sources;
                  })), "");
}

function usage_terms($$document) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                    return d.usage_terms;
                  })), "");
}

function author_job_title($$document) {
  return Prelude.default(Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                    return d.author_job_title;
                  })), "");
}

function country_code($$document) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                return d.country_code;
              }));
}

function height($$document) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                return d.height;
              }));
}

function width($$document) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                return d.width;
              }));
}

function dimensions($$document) {
  return Curry._2(Prelude.OptionExported.$$Option.flatMap, $$document.denormalized, (function (d) {
                return d.dimensions;
              }));
}

function setName($$document, name) {
  var newrecord = Caml_obj.obj_dup($$document);
  newrecord.name = name;
  return newrecord;
}

function setTags($$document, tags) {
  var newrecord = Caml_obj.obj_dup($$document);
  newrecord.tags = tags;
  return newrecord;
}

function setDescription($$document, description) {
  var newrecord = Caml_obj.obj_dup($$document);
  newrecord.description = description;
  return newrecord;
}

function setCountry($$document, country) {
  return set($$document, country);
}

function removeCountry($$document) {
  return remove($$document);
}

function setCountryCode($$document, countr_ycode) {
  return set$1($$document, countr_ycode);
}

function removeCountryCode($$document) {
  return remove$1($$document);
}

function setCity($$document, city) {
  return set$2($$document, city);
}

function removeCity($$document) {
  return remove$2($$document);
}

function setAuthor($$document, author) {
  return set$3($$document, author);
}

function removeAuthor($$document) {
  return remove$3($$document);
}

function setAuthorJobTitle($$document, author_job_title) {
  return set$9($$document, author_job_title);
}

function removeAuthorJobTitle($$document) {
  return remove$9($$document);
}

function setCopyright($$document, copyright) {
  return set$4($$document, copyright);
}

function removeCopyright($$document) {
  return remove$4($$document);
}

function setHeadline($$document, headline) {
  return set$6($$document, headline);
}

function removeHeadline($$document) {
  return remove$6($$document);
}

function setSources($$document, sources) {
  return set$7($$document, sources);
}

function removeSources($$document) {
  return remove$7($$document);
}

function setUsageTerms($$document, usage_terms) {
  return set$8($$document, usage_terms);
}

function removeUsageTerms($$document) {
  return remove$8($$document);
}

function setEvent($$document, $$event) {
  return set$5($$document, $$event);
}

function removeEvent($$document) {
  return remove$5($$document);
}

var _map = {"name":"name","author":"author","created_at":"created_at","dimensions":"dimensions","city":"city","country":"country"};

function fieldToJs(param) {
  return param;
}

function fieldFromJs(param) {
  return _map[param];
}

var _map$1 = {"asc":"asc","desc":"desc"};

function directionToJs(param) {
  return param;
}

function directionFromJs(param) {
  return _map$1[param];
}

function sortkey(doc, dir) {
  var value = Curry._2(Prelude.OptionExported.$$Option.flatMap, doc.denormalized, (function (d) {
          return Curry._2(Prelude.OptionExported.$$Option.map, d.author, (function (a) {
                        return a.toLocaleLowerCase().trim();
                      }));
        }));
  if (value !== undefined) {
    if (value === "") {
      return [
              dir === "asc" ? 1 : -1,
              "",
              doc.id
            ];
    } else {
      return [
              0,
              value,
              doc.id
            ];
    }
  } else {
    return [
            dir === "asc" ? 1 : -1,
            "",
            doc.id
          ];
  }
}

var Author$1 = {
  sortkey: sortkey
};

function sortkey$1(doc, dir) {
  var value = Curry._2(Prelude.OptionExported.$$Option.flatMap, doc.denormalized, (function (d) {
          return Curry._2(Prelude.OptionExported.$$Option.map, d.country, (function (a) {
                        return a.toLocaleLowerCase().trim();
                      }));
        }));
  if (value !== undefined) {
    if (value === "") {
      return [
              dir === "asc" ? 1 : -1,
              "",
              doc.id
            ];
    } else {
      return [
              0,
              value,
              doc.id
            ];
    }
  } else {
    return [
            dir === "asc" ? 1 : -1,
            "",
            doc.id
          ];
  }
}

var Country$1 = {
  sortkey: sortkey$1
};

function sortkey$2(doc, dir) {
  var value = Curry._2(Prelude.OptionExported.$$Option.flatMap, doc.denormalized, (function (d) {
          return Curry._2(Prelude.OptionExported.$$Option.map, d.city, (function (c) {
                        return c.toLocaleLowerCase().trim();
                      }));
        }));
  if (value !== undefined) {
    if (value === "") {
      return [
              dir === "asc" ? 1 : -1,
              "",
              doc.id
            ];
    } else {
      return [
              0,
              value,
              doc.id
            ];
    }
  } else {
    return [
            dir === "asc" ? 1 : -1,
            "",
            doc.id
          ];
  }
}

var City$1 = {
  sortkey: sortkey$2
};

function sortkey$3(doc, dir) {
  var value = Curry._2(Prelude.OptionExported.$$Option.flatMap, doc.denormalized, (function (d) {
          return d.dimensions;
        }));
  if (value !== undefined) {
    return [
            0,
            value,
            doc.id
          ];
  } else {
    return [
            dir === "asc" ? 1 : -1,
            0,
            doc.id
          ];
  }
}

var Dimensions$1 = {
  sortkey: sortkey$3
};

function sort(direction, field) {
  return function (docs) {
    var result = field === "name" ? Prelude.$$Array.sort(docs, (function (doc) {
              return doc.name.toLocaleLowerCase();
            }), undefined) : (
        field === "dimensions" ? Prelude.$$Array.sort(docs, (function (doc) {
                  return sortkey$3(doc, direction);
                }), undefined) : (
            field === "created_at" ? Prelude.$$Array.sort(docs, (function (doc) {
                      return doc.created_at.valueOf();
                    }), undefined) : (
                field === "author" ? Prelude.$$Array.sort(docs, (function (doc) {
                          return sortkey(doc, direction);
                        }), undefined) : (
                    field === "country" ? Prelude.$$Array.sort(docs, (function (doc) {
                              return sortkey$1(doc, direction);
                            }), undefined) : Prelude.$$Array.sort(docs, (function (doc) {
                              return sortkey$2(doc, direction);
                            }), undefined)
                  )
              )
          )
      );
    if (direction === "asc") {
      return result;
    } else {
      return Curry._1(Prelude.$$Array.reverse, result);
    }
  };
}

var Order = {
  fieldToJs: fieldToJs,
  fieldFromJs: fieldFromJs,
  directionToJs: directionToJs,
  directionFromJs: directionFromJs,
  Author: Author$1,
  Country: Country$1,
  City: City$1,
  Dimensions: Dimensions$1,
  sort: sort
};

var Identifier;

var clear = include.clear;

var save = include.save;

var $$delete = include.$$delete;

var remove$13 = include.remove;

var IdComparator = include.IdComparator;

var Id = include.Id;

export {
  Identifier ,
  Definition ,
  clear ,
  save ,
  $$delete ,
  remove$13 as remove,
  IdComparator ,
  Id ,
  _getFiles ,
  getFiles ,
  clone ,
  Denormalization ,
  fromBackend ,
  Orientation$1 as Orientation,
  merge ,
  fileName ,
  name ,
  author ,
  copyright ,
  country ,
  city ,
  $$event ,
  headline ,
  sources ,
  usage_terms ,
  author_job_title ,
  country_code ,
  height ,
  width ,
  dimensions ,
  setName ,
  setTags ,
  setDescription ,
  setCountry ,
  removeCountry ,
  setCountryCode ,
  removeCountryCode ,
  setCity ,
  removeCity ,
  setAuthor ,
  removeAuthor ,
  setAuthorJobTitle ,
  removeAuthorJobTitle ,
  setCopyright ,
  removeCopyright ,
  setHeadline ,
  removeHeadline ,
  setSources ,
  removeSources ,
  setUsageTerms ,
  removeUsageTerms ,
  setEvent ,
  removeEvent ,
  Order ,
}
/* include Not a pure module */
