// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Folder from "../../apps/catalog/models/Folder.js";
import * as Prelude from "@kaiko.io/rescript-prelude/lib/es6/src/Prelude.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as $$Document from "../../apps/catalog/models/Document.js";
import * as FolderId from "../../apps/catalog/models/FolderId.js";
import * as LocalFile from "../../utils/LocalFile.js";
import * as DocumentId from "../../apps/catalog/models/DocumentId.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamespaceId from "./ids/NamespaceId.js";
import * as CollectionId from "../../apps/collections/models/CollectionId.js";

function make() {
  return {
          collections: CollectionId.MutableMap.make(),
          documents: DocumentId.MutableMap.make(),
          files: LocalFile.UUID.MutableMap.make(),
          folders: FolderId.MutableMap.make(),
          namespaces: NamespaceId.MutableMap.make()
        };
}

var Core = {
  make: make
};

function MakeView(T) {
  var Id = T.Id;
  var map = T.map;
  var all = function (state) {
    return Id.MutableMap.valuesToArray(T.map(state));
  };
  var forEach = function (state, fn) {
    Curry._2(Prelude.$$Array.forEach, all(state), fn);
  };
  var update = function (state, item) {
    var eq = Prelude.default(T.equal, Caml_obj.equal);
    var collection = T.map(state);
    var existing = Id.MutableMap.get(collection, T.key(item));
    if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
      return ;
    } else {
      return Id.MutableMap.set(collection, T.key(item), item);
    }
  };
  var updateMany = function (state, items) {
    Curry._2(Prelude.$$Array.forEach, items, (function (i) {
            update(state, i);
          }));
    return state;
  };
  var remove = function (state, item) {
    Id.MutableMap.remove(T.map(state), T.key(item));
  };
  var get = function (state, key) {
    var collection = T.map(state);
    return Id.MutableMap.get(collection, key);
  };
  var getActions = function (initial, $$final) {
    var eq = Prelude.default(T.equal, Caml_obj.equal);
    var initial$1 = T.map(initial);
    var $$final$1 = T.map($$final);
    var removing = Id.$$Set.diff(Id.$$Set.fromArray(Id.MutableMap.keysToArray(initial$1)), Id.$$Set.fromArray(Id.MutableMap.keysToArray($$final$1)));
    var $$new = Curry._2(Prelude.$$Array.keepMap, Id.MutableMap.toArray($$final$1), (function (param) {
            var value = param[1];
            var current = Id.MutableMap.get(initial$1, param[0]);
            if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
              return ;
            } else {
              return Caml_option.some(value);
            }
          }));
    return Curry._2(Prelude.$$Array.concat, Id.$$Set.toArray(removing).map(function (id) {
                    return {
                            TAG: "Delete",
                            _0: id
                          };
                  }), $$new.map(function (r) {
                    return {
                            TAG: "Save",
                            _0: r
                          };
                  }));
  };
  var clear = function (state) {
    Id.MutableMap.clear(map(state));
  };
  return {
          Model: T.Model,
          Id: Id,
          key: T.key,
          map: map,
          equal: T.equal,
          all: all,
          get: get,
          update: update,
          updateMany: updateMany,
          remove: remove,
          getActions: getActions,
          forEach: forEach,
          clear: clear,
          getFiles: T.getFiles
        };
}

function MakeNonDBView(T) {
  var Id = T.Id;
  var map = T.map;
  var all = function (state) {
    return Id.MutableMap.valuesToArray(T.map(state));
  };
  var forEach = function (state, fn) {
    Curry._2(Prelude.$$Array.forEach, all(state), fn);
  };
  var update = function (state, item) {
    var eq = Prelude.default(T.equal, Caml_obj.equal);
    var collection = T.map(state);
    var existing = Id.MutableMap.get(collection, T.key(item));
    if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
      return ;
    } else {
      return Id.MutableMap.set(collection, T.key(item), item);
    }
  };
  var updateMany = function (state, items) {
    Curry._2(Prelude.$$Array.forEach, items, (function (i) {
            update(state, i);
          }));
    return state;
  };
  var remove = function (state, item) {
    Id.MutableMap.remove(T.map(state), T.key(item));
  };
  var get = function (state, key) {
    var collection = T.map(state);
    return Id.MutableMap.get(collection, key);
  };
  var getActions = function (initial, $$final) {
    var eq = Prelude.default(T.equal, Caml_obj.equal);
    var initial$1 = T.map(initial);
    var $$final$1 = T.map($$final);
    var removing = Id.$$Set.diff(Id.$$Set.fromArray(Id.MutableMap.keysToArray(initial$1)), Id.$$Set.fromArray(Id.MutableMap.keysToArray($$final$1)));
    var $$new = Curry._2(Prelude.$$Array.keepMap, Id.MutableMap.toArray($$final$1), (function (param) {
            var value = param[1];
            var current = Id.MutableMap.get(initial$1, param[0]);
            if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
              return ;
            } else {
              return Caml_option.some(value);
            }
          }));
    return Curry._2(Prelude.$$Array.concat, Id.$$Set.toArray(removing).map(function (id) {
                    return {
                            TAG: "Delete",
                            _0: id
                          };
                  }), $$new.map(function (r) {
                    return {
                            TAG: "Update",
                            _0: r
                          };
                  }));
  };
  var clear = function (state) {
    Id.MutableMap.clear(map(state));
  };
  return {
          Id: Id,
          key: T.key,
          map: map,
          equal: T.equal,
          all: all,
          get: get,
          update: update,
          updateMany: updateMany,
          remove: remove,
          forEach: forEach,
          clear: clear,
          getActions: getActions
        };
}

function key(record) {
  return record.id;
}

function map(state) {
  return state.folders;
}

function getFiles(state) {
  return Folder.getFiles(FolderId.MutableMap.valuesToArray(state.folders));
}

var T_Model = {
  clear: Folder.clear,
  save: Folder.save,
  $$delete: Folder.$$delete,
  remove: Folder.remove,
  IdComparator: Folder.IdComparator,
  Id: Folder.Id
};

var Id = FolderId;

function all(state) {
  return Id.MutableMap.valuesToArray(state.folders);
}

function forEach(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all(state), fn);
}

function update(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.folders;
  var existing = Id.MutableMap.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id.MutableMap.set(collection, item.id, item);
  }
}

function updateMany(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (i) {
          update(state, i);
        }));
  return state;
}

function remove(state, item) {
  Id.MutableMap.remove(state.folders, item.id);
}

function get(state, key) {
  var collection = state.folders;
  return Id.MutableMap.get(collection, key);
}

function getActions(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.folders;
  var $$final$1 = $$final.folders;
  var removing = Id.$$Set.diff(Id.$$Set.fromArray(Id.MutableMap.keysToArray(initial$1)), Id.$$Set.fromArray(Id.MutableMap.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id.MutableMap.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id.MutableMap.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear(state) {
  Id.MutableMap.clear(state.folders);
}

var Folders_Model = T_Model;

var Folders = {
  Model: Folders_Model,
  Id: Id,
  key: key,
  map: map,
  equal: undefined,
  all: all,
  get: get,
  update: update,
  updateMany: updateMany,
  remove: remove,
  getActions: getActions,
  forEach: forEach,
  clear: clear,
  getFiles: getFiles
};

function key$1(record) {
  return record.id;
}

function map$1(state) {
  return state.documents;
}

function getFiles$1(state) {
  return $$Document.getFiles(DocumentId.MutableMap.valuesToArray(state.documents));
}

var T_Model$1 = {
  clear: $$Document.clear,
  save: $$Document.save,
  $$delete: $$Document.$$delete,
  remove: $$Document.remove,
  IdComparator: $$Document.IdComparator,
  Id: $$Document.Id
};

var Id$1 = DocumentId;

function all$1(state) {
  return Id$1.MutableMap.valuesToArray(state.documents);
}

function forEach$1(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$1(state), fn);
}

function update$1(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.documents;
  var existing = Id$1.MutableMap.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$1.MutableMap.set(collection, item.id, item);
  }
}

function updateMany$1(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (i) {
          update$1(state, i);
        }));
  return state;
}

function remove$1(state, item) {
  Id$1.MutableMap.remove(state.documents, item.id);
}

function get$1(state, key) {
  var collection = state.documents;
  return Id$1.MutableMap.get(collection, key);
}

function getActions$1(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.documents;
  var $$final$1 = $$final.documents;
  var removing = Id$1.$$Set.diff(Id$1.$$Set.fromArray(Id$1.MutableMap.keysToArray(initial$1)), Id$1.$$Set.fromArray(Id$1.MutableMap.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$1.MutableMap.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$1.MutableMap.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$1.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Save",
                          _0: r
                        };
                }));
}

function clear$1(state) {
  Id$1.MutableMap.clear(state.documents);
}

var Documents_Model = T_Model$1;

var Documents = {
  Model: Documents_Model,
  Id: Id$1,
  key: key$1,
  map: map$1,
  equal: undefined,
  all: all$1,
  get: get$1,
  update: update$1,
  updateMany: updateMany$1,
  remove: remove$1,
  getActions: getActions$1,
  forEach: forEach$1,
  clear: clear$1,
  getFiles: getFiles$1
};

function key$2(record) {
  return record.id;
}

function map$2(state) {
  return state.namespaces;
}

var Id$2 = NamespaceId;

function all$2(state) {
  return Id$2.MutableMap.valuesToArray(state.namespaces);
}

function forEach$2(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$2(state), fn);
}

function update$2(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.namespaces;
  var existing = Id$2.MutableMap.get(collection, item.id);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return Id$2.MutableMap.set(collection, item.id, item);
  }
}

function updateMany$2(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (i) {
          update$2(state, i);
        }));
  return state;
}

function remove$2(state, item) {
  Id$2.MutableMap.remove(state.namespaces, item.id);
}

function get$2(state, key) {
  var collection = state.namespaces;
  return Id$2.MutableMap.get(collection, key);
}

function getActions$2(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.namespaces;
  var $$final$1 = $$final.namespaces;
  var removing = Id$2.$$Set.diff(Id$2.$$Set.fromArray(Id$2.MutableMap.keysToArray(initial$1)), Id$2.$$Set.fromArray(Id$2.MutableMap.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, Id$2.MutableMap.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = Id$2.MutableMap.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, Id$2.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Update",
                          _0: r
                        };
                }));
}

function clear$2(state) {
  Id$2.MutableMap.clear(state.namespaces);
}

var Namespaces = {
  Id: Id$2,
  key: key$2,
  map: map$2,
  equal: undefined,
  all: all$2,
  get: get$2,
  update: update$2,
  updateMany: updateMany$2,
  remove: remove$2,
  forEach: forEach$2,
  clear: clear$2,
  getActions: getActions$2
};

function key$3(record) {
  return record;
}

function map$3(state) {
  return state.files;
}

function all$3(state) {
  return LocalFile.UUID.MutableMap.valuesToArray(state.files);
}

function forEach$3(state, fn) {
  Curry._2(Prelude.$$Array.forEach, all$3(state), fn);
}

function update$3(state, item) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var collection = state.files;
  var existing = LocalFile.UUID.MutableMap.get(collection, item);
  if (existing !== undefined && eq(Caml_option.valFromOption(existing), item)) {
    return ;
  } else {
    return LocalFile.UUID.MutableMap.set(collection, item, item);
  }
}

function updateMany$3(state, items) {
  Curry._2(Prelude.$$Array.forEach, items, (function (i) {
          update$3(state, i);
        }));
  return state;
}

function remove$3(state, item) {
  LocalFile.UUID.MutableMap.remove(state.files, item);
}

function get$3(state, key) {
  var collection = state.files;
  return LocalFile.UUID.MutableMap.get(collection, key);
}

function getActions$3(initial, $$final) {
  var eq = Prelude.default(undefined, Caml_obj.equal);
  var initial$1 = initial.files;
  var $$final$1 = $$final.files;
  var removing = LocalFile.UUID.$$Set.diff(LocalFile.UUID.$$Set.fromArray(LocalFile.UUID.MutableMap.keysToArray(initial$1)), LocalFile.UUID.$$Set.fromArray(LocalFile.UUID.MutableMap.keysToArray($$final$1)));
  var $$new = Curry._2(Prelude.$$Array.keepMap, LocalFile.UUID.MutableMap.toArray($$final$1), (function (param) {
          var value = param[1];
          var current = LocalFile.UUID.MutableMap.get(initial$1, param[0]);
          if (current !== undefined && eq(Caml_option.valFromOption(current), value)) {
            return ;
          } else {
            return Caml_option.some(value);
          }
        }));
  return Curry._2(Prelude.$$Array.concat, LocalFile.UUID.$$Set.toArray(removing).map(function (id) {
                  return {
                          TAG: "Delete",
                          _0: id
                        };
                }), $$new.map(function (r) {
                  return {
                          TAG: "Update",
                          _0: r
                        };
                }));
}

function clear$3(state) {
  LocalFile.UUID.MutableMap.clear(state.files);
}

var Files = {
  Id: LocalFile.UUID,
  key: key$3,
  map: map$3,
  equal: undefined,
  all: all$3,
  get: get$3,
  update: update$3,
  updateMany: updateMany$3,
  remove: remove$3,
  forEach: forEach$3,
  clear: clear$3,
  getActions: getActions$3
};

export {
  Core ,
  make ,
  MakeView ,
  MakeNonDBView ,
  Folders ,
  Documents ,
  Namespaces ,
  Files ,
}
/* Folder Not a pure module */
